import { computed } from "vue";
import { type RouteLocationAsRelativeGeneric, useRoute, useRouter } from "vue-router";

import useConfirmDialog from "./use-confirm-messages";

export function useCampaignDuplicationRedirection() {
  const { confirmInfo } = useConfirmDialog();
  const router = useRouter();
  const currentRoute = useRoute();

  async function redirect(route: RouteLocationAsRelativeGeneric) {
    const adDuplicationConfirmed = await confirmInfo("Do you want to assign existing ads to the duplicated campaign?");
    void router.push({
      name: route.name,
      params: { ...route.params, ...(adDuplicationConfirmed ? { allowAdsDuplication: "with-ads" } : {}) },
    });
  };

  const allowAdsDuplication = computed(() => currentRoute.params.allowAdsDuplication === "with-ads");

  return {
    redirect,
    allowAdsDuplication,
  };
}
