<script lang="ts" setup>
import type { ButtonProps } from "primevue/button";
import InputNumber from "primevue/inputnumber";
import { computed, nextTick, ref, watchEffect } from "vue";

const props = withDefaults(
  defineProps<{
    modelValue: number | null;
    autofocus?: boolean;
    fractionDigits?: number;
    allowEmpty?: boolean;
    size?: ButtonProps["size"] | null;
    mode?: "currency" | "percent" | "decimal";
    prefix?: string;
  }>(),
  {
    fractionDigits: 0,
    size: null,
    mode: "decimal",
  },
);

const emit = defineEmits<{
  "update:modelValue": [value: number | null];
}>();

const currentValue = ref<number | null>(null);
const inputRef = ref();

watchEffect(() => {
  if (props.allowEmpty && props.modelValue === null) {
    currentValue.value = null;
    return;
  }

  const valueNumber = props.modelValue ?? 0;
  currentValue.value = valueNumber;
});

const currency = computed(() => {
  if (props.mode === "currency") {
    return "USD";
  }

  return undefined;
});

const inputMode = computed(() => {
  if (props.mode === "percent") {
    return "decimal";
  }

  return props.mode;
});

const inputPrefix = computed(() => {
  if (props.prefix) {
    return props.prefix;
  }

  if (props.mode === "currency") {
    return "$";
  }

  if (props.mode === "percent") {
    return "% ";
  }

  return props.prefix;
});

async function onFocus() {
  await nextTick();
  if (inputRef.value) {
    inputRef.value.$el.children[0].select();
  }
}

function onUpdateModelValue(value: number | null) {
  currentValue.value = value;
  emit("update:modelValue", value);
}
</script>

<template>
  <InputNumber
    ref="inputRef"
    v-model="currentValue"
    locale="en-US"
    :autofocus="autofocus"
    :currency="currency"
    :max-fraction-digits="fractionDigits"
    :min-fraction-digits="fractionDigits"
    :mode="inputMode"
    :prefix="inputPrefix"
    :size="size ?? undefined"
    @focus="onFocus"
    @input="(e) => onUpdateModelValue(e.value as number)"
  />
</template>
